<template>
  <div :class="['input-drop-zone rounded', hover ? 'hover' : '']" @click="$refs.files.click()" @drop.prevent="dropFile" @dragenter="dragEnter" @dragover.prevent="dragEnter" @dragleave="dragLeave">
    <input
      type="file"
      ref="files"
      name="files"
      class="input-drop-zone-input"
      :multiple="multiple"
      @change="loadFile"
    >
    <slot/>
  </div>
</template>

<script>

const _getBase64File = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  const name = file.name.split('.')
  name.pop()
  reader.readAsDataURL(file)
  reader.onload = () => resolve({
    [name.join('.')]: reader.result
  })
  reader.onerror = (e) => {
    console.error(e)
    resolve()
  }
})

const _compileFiles = (files) => Promise.all(Array.from(files).map(_getBase64File))

export default {
  name: 'InputDropzone',
  data: () => ({
    hover: false,
    files: []
  }),
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    acceptFileType: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '150px'
    }
  },
  methods: {
    dropFile (event) {
      this.hover = false
      const files = event.dataTransfer.files || []
      if (files.length > 0) {
        return _compileFiles(files)
          .then(results => {
            const tmp = Object.assign({}, ...results.filter(v => v))
            this.$emit('input', tmp)
            this.$emit('change', tmp)
          })
      }
      return Promise.resolve()
    },

    dragEnter (event) {
      console.log('dragEnter')
      this.hover = true
    },

    dragLeave (event) {
      console.log('dragLeave')
      this.hover = false
    },



    loadFile() {
      this.hover = false
      const results = {}
      const el = this.$refs.files
      const files = el.files || []
      if (files.length > 0) {
        return _compileFiles(files)
          .then(results => {
            const tmp = Object.assign({}, ...results.filter(v => v))
            this.$emit('input', tmp)
            this.$emit('change', tmp)
          })
      }
      return Promise.resolve()
    }

  }
}
</script>

<style>
  .input-drop-zone-input {
    display: none;
  }
  .input-drop-zone:hover {
    background-color: rgba(0,0,0,.05);
  }
  .input-drop-zone.hover {
    border: dashed 2px #aaa;
  }
  .input-drop-zone.hover > * {
    opacity: .5;
    pointer-events: none;
  }
  .input-drop-zone {
    user-select: none;
    cursor: pointer;
    border: solid 2px #ddd;
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 150px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
</style>
